.custom-toast {
  padding: 16px;
  background: #222222;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  min-height: auto;
  cursor: pointer;
}
